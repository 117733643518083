import type { FileId, UserDisplayModel } from '@mntn-dev/domain-types'
import { type Person, toPerson } from '@mntn-dev/ui-components'

export type GetAvatarUrl = (fileId: FileId | undefined) => string | undefined

export const userToPerson = (
  user: UserDisplayModel | null,
  getAvatarUrl: GetAvatarUrl
): Person => {
  return toPerson({
    firstName: user?.firstName || '-',
    lastName: user?.lastName || '-',
    avatarFileId: user?.avatarFileId,
    avatarUrl: getAvatarUrl(user?.avatarFileId),
    organizationType: 'brand',
  })
}
