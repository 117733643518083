import { linkTargetResolver } from '@mntn-dev/app-routing'
import type {
  PreProductionReviewBrandFeedbackSubmittedReviewStatus,
  PreProductionReviewBrandFeedbackSubmittedRoundStatus,
} from '@mntn-dev/domain-types'
import { Feed } from '@mntn-dev/ui-components'
import { ActivityContainer } from '../activity-container.tsx'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const PreProductionReviewBrandFeedbackSubmitted = ({
  activity,
  activity: {
    activityId,
    actor,
    details: {
      activityType,
      data: { reviewStatus, roundStatus, roundNumber, target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'pre_production_review_brand_feedback_submitted'>) => {
  const i18nKey = <Property extends 'summary' | 'status'>(
    property: Property
  ):
    | `${typeof activityType}.${Exclude<PreProductionReviewBrandFeedbackSubmittedReviewStatus, 'concepting'>}.${Property}`
    | `${typeof activityType}.${Extract<PreProductionReviewBrandFeedbackSubmittedReviewStatus, 'concepting'>}.${PreProductionReviewBrandFeedbackSubmittedRoundStatus}.${Property}` =>
    reviewStatus === 'resolved'
      ? `${activityType}.${reviewStatus}.${property}`
      : `${activityType}.${reviewStatus}.${roundStatus}.${property}`

  return (
    <ActivityContainer {...{ activity }}>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={i18nKey('summary')}
          values={{
            subject: actor.displayName,
            roundNumber,
            service: target.service,
          }}
        />
      </Feed.Summary>
      <Feed.Annotations>
        <Feed.Tag
          type={roundStatus === 'approved' ? 'success' : 'error'}
          dataTestId={`activity-feed-item-${activityId}-status-tag`}
          dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
        >
          {t(i18nKey('status'))}
        </Feed.Tag>
        <Link
          {...{
            target: linkTargetResolver['project-service']({ target }),
            t,
            onNavigate,
            currentUrl,
            dataTestId: `activity-feed-item-${activityId}-view-project-link`,
            dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
          }}
        />
      </Feed.Annotations>
    </ActivityContainer>
  )
}
