import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed, Stack } from '@mntn-dev/ui-components'
import { ActivityContainer } from '../activity-container.tsx'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectBidRejected = ({
  activity,
  activity: {
    activityId,
    details: {
      activityType,
      data: { target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'project_bid_rejected'>) => {
  const i18nPropertyKey = <Property extends 'summary' | 'status'>(
    property: Property
  ): `${typeof activityType}.${Property}` => `${activityType}.${property}`

  return (
    <ActivityContainer {...{ activity }}>
      <Feed.Summary>
        <Stack direction="col" gap="2">
          <div>
            <ActivityTrans
              t={t}
              i18nKey={i18nPropertyKey('summary')}
              values={{
                project: target.project,
              }}
            />
          </div>
        </Stack>
      </Feed.Summary>
      {t(i18nPropertyKey('status')) && (
        <Feed.Annotations>
          <Feed.Tag
            type="info"
            dataTestId={`activity-feed-item-${activityId}-status-tag`}
            dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
          >
            {t(i18nPropertyKey('status'))}
          </Feed.Tag>
          <Link
            {...{
              target: linkTargetResolver.project({ target }),
              t,
              onNavigate,
              currentUrl,
              dataTestId: `activity-feed-item-${activityId}-view-project-link`,
              dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
            }}
          />
        </Feed.Annotations>
      )}
    </ActivityContainer>
  )
}
