import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed } from '@mntn-dev/ui-components'
import { ActivityContainer } from '../activity-container.tsx'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const AgreementTermsAccepted = ({
  activity,
  activity: {
    activityId,
    actor,
    details: {
      activityType,
      data: { type, target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'agreement_terms_accepted'>) => (
  <ActivityContainer {...{ activity }}>
    <Feed.Summary>
      <ActivityTrans
        t={t}
        i18nKey={`${activityType}.summary`}
        values={{
          subject: actor.displayName,
          agreementType: t(`${activityType}.agreementType.${type}`),
          project: target.project,
        }}
      />
    </Feed.Summary>
    <Feed.Annotations>
      <Feed.Tag
        type="warning"
        dataTestId={`activity-feed-item-${activityId}-status-tag`}
        dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
      >
        {t(`${activityType}.status`)}
      </Feed.Tag>
      <Link
        {...{
          target: linkTargetResolver.project({ target }),
          t,
          onNavigate,
          currentUrl,
          dataTestId: `activity-feed-item-${activityId}-view-project-link`,
          dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
        }}
      />
    </Feed.Annotations>
  </ActivityContainer>
)
