import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed } from '@mntn-dev/ui-components'
import { ActivityContainer } from '../activity-container.tsx'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const CommentCreated = ({
  activity,
  activity: {
    activityId,
    actor,
    details: {
      activityType,
      data: { target, text },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'comment_created'>) => (
  <ActivityContainer {...{ activity }}>
    <Feed.Summary>
      <ActivityTrans
        t={t}
        i18nKey={`${activityType}.summary`}
        values={{
          subject: actor.displayName,
          feed: target.feed,
        }}
      />
    </Feed.Summary>
    <Feed.Quote>{text}</Feed.Quote>
    <Feed.Annotations>
      <Link
        {...{
          target: linkTargetResolver.activity({ target, activityId }),
          t,
          onNavigate,
          currentUrl,
          dataTestId: `activity-feed-item-${activityId}-view-feed-link`,
          dataTrackingId: `activity-feed-item-${activityId}-view-feed-link`,
        }}
      />
    </Feed.Annotations>
  </ActivityContainer>
)
