import { linkTargetResolver } from '@mntn-dev/app-routing'
import { Feed } from '@mntn-dev/ui-components'
import { ActivityContainer } from '../activity-container.tsx'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectOfferPending = ({
  activity,
  activity: {
    activityId,
    actor,
    details: {
      activityType,
      data: { target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'project_offer_pending'>) => (
  <ActivityContainer {...{ activity }}>
    <Feed.Summary>
      <ActivityTrans
        t={t}
        i18nKey={`${activityType}.summary`}
        values={{
          subject: actor.displayName,
          offer: target.offer,
        }}
      />
    </Feed.Summary>
    <Feed.Annotations>
      <Feed.Tag
        type="notice"
        dataTestId={`activity-feed-item-${activityId}-status-tag`}
        dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
      >
        {t(`${activityType}.status`)}
      </Feed.Tag>
      <Link
        {...{
          target: linkTargetResolver.offer({ target }),
          t,
          onNavigate,
          currentUrl,
          dataTestId: `activity-feed-item-${activityId}-view-offer-link`,
          dataTrackingId: `activity-feed-item-${activityId}-view-offer-link`,
        }}
      />
    </Feed.Annotations>
  </ActivityContainer>
)
