import { useLanguage } from '@mntn-dev/i18n'
import { SystemUserId } from '@mntn-dev/session-manager'
import { Avatar, Feed } from '@mntn-dev/ui-components'
import type { PropsWithChildren } from 'react'
import type { ActivityProps } from '../types.ts'

type ActivityContainerProps = PropsWithChildren<
  Pick<ActivityProps, 'activity' | 'indicator' | 'scroll'>
>

export const ActivityContainer = ({
  activity: { activityId, timestamp, actor, actorId },
  indicator,
  children,
  scroll,
}: ActivityContainerProps) => {
  const languageId = useLanguage()

  return (
    <Feed.Item
      avatar={
        actorId === SystemUserId ? (
          <Avatar.Icon name="ExclamationIcon" />
        ) : (
          <Avatar.Person person={actor} />
        )
      }
      key={activityId}
      timestamp={timestamp}
      languageId={languageId}
      indicator={indicator}
      scroll={scroll}
      dataTestId={`activity-feed-item-${activityId}`}
      dataTrackingId={`activity-feed-item-${activityId}`}
    >
      {children}
    </Feed.Item>
  )
}
