import { linkTargetResolver } from '@mntn-dev/app-routing'
import { useLocale } from '@mntn-dev/i18n'
import { SystemUserId } from '@mntn-dev/session-manager'
import { Feed, Stack } from '@mntn-dev/ui-components'
import { addDays, formatDate } from '@mntn-dev/utilities'
import type { ActivityVariant } from '../../types.ts'
import { ActivityContainer } from '../activity-container.tsx'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectBiddingOpened = ({
  activity,
  activity: {
    activityId,
    actor,
    actorId,
    details: {
      activityType,
      data: { biddingCloseDate, target },
    },
  },
  t,
  onNavigate,
  currentUrl,
  variant,
}: ActivityDetailsProps<'project_bidding_opened'>) => {
  const locale = useLocale()

  const isBiddingWindowOpen = Date.now() < biddingCloseDate.getTime()

  const biddingWindowStatus = isBiddingWindowOpen ? 'open' : 'closed'

  const isBiddingClosingSoon =
    isBiddingWindowOpen && addDays(Date.now(), 4) > biddingCloseDate

  const i18nPropertyKey = <Property extends 'summary' | 'details' | 'status'>(
    property: Property
  ): `${typeof activityType}.${ActivityVariant}.${typeof biddingWindowStatus}.${Property}` =>
    `${activityType}.${variant}.${biddingWindowStatus}.${property}`

  return (
    <ActivityContainer
      {...{
        activity: {
          ...activity,
          actorId: variant === 'feed' ? actorId : SystemUserId,
        },
      }}
    >
      <Feed.Summary>
        <Stack direction="col" gap="2">
          <div>
            <ActivityTrans
              t={t}
              i18nKey={i18nPropertyKey('summary')}
              values={{
                subject: actor.displayName,
                project: target.project,
                brandTeam: target.brandTeam,
              }}
            />
          </div>
          <div>
            <ActivityTrans
              t={t}
              i18nKey={i18nPropertyKey('details')}
              values={{
                biddingCloseDate: formatDate(
                  biddingCloseDate,
                  isBiddingClosingSoon ? 'relative-time' : 'medium-date',
                  locale
                ),
              }}
            />
          </div>
        </Stack>
      </Feed.Summary>
      <Feed.Annotations>
        <Feed.Tag
          type={
            variant === 'feed'
              ? 'notice'
              : isBiddingWindowOpen
                ? 'info'
                : 'default'
          }
          dataTestId={`activity-feed-item-${activityId}-status-tag`}
          dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
        >
          {t(i18nPropertyKey('status'))}
        </Feed.Tag>
        <Link
          {...{
            target: linkTargetResolver.project({ target }),
            t,
            onNavigate,
            currentUrl,
            dataTestId: `activity-feed-item-${activityId}-view-project-link`,
            dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
          }}
        />
      </Feed.Annotations>
    </ActivityContainer>
  )
}
