import { linkTargetResolver } from '@mntn-dev/app-routing'
import type { PreProductionReviewMakerProposalSubmittedReviewStatus } from '@mntn-dev/domain-types'
import { Feed } from '@mntn-dev/ui-components'
import { ActivityContainer } from '../activity-container.tsx'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../link.tsx'
import type { ActivityDetailsProps } from './types.ts'

export const PreProductionReviewMakerProposalSubmitted = ({
  activity,
  activity: {
    activityId,
    actor,
    details: {
      activityType,
      data: { reviewStatus, roundNumber, target },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'pre_production_review_maker_proposal_submitted'>) => {
  const i18nPropertyKey = <Property extends 'summary' | 'status'>(
    property: Property
  ): `${typeof activityType}.${PreProductionReviewMakerProposalSubmittedReviewStatus}.${Property}` =>
    `${activityType}.${reviewStatus}.${property}`

  return (
    <ActivityContainer {...{ activity }}>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={i18nPropertyKey('summary')}
          values={{
            subject: actor.displayName,
            roundNumber,
            service: target.service,
          }}
        />
      </Feed.Summary>
      <Feed.Annotations>
        <Feed.Tag
          type="error"
          dataTestId={`activity-feed-item-${activityId}-status-tag`}
          dataTrackingId={`activity-feed-item-${activityId}-status-tag`}
        >
          {t(i18nPropertyKey('status'))}
        </Feed.Tag>
        <Link
          {...{
            target: linkTargetResolver['project-service']({ target }),
            t,
            onNavigate,
            currentUrl,
            dataTestId: `activity-feed-item-${activityId}-view-project-link`,
            dataTrackingId: `activity-feed-item-${activityId}-view-project-link`,
          }}
        />
      </Feed.Annotations>
    </ActivityContainer>
  )
}
