import { trpcReactClient } from '~/app/_trpc/trpc-react-client.ts'

export const useMe = () => {
  const { data: me, refetch: refetchMe } = trpcReactClient.users.getMe.useQuery(
    undefined,
    {
      refetchOnMount: false,
    }
  )

  /**
   * This asserts `me` should always be present in the cache.
   * Normally we could do `useSuspenseQuery()`, but this approach allows us to craft a more specific error message if misused.
   *
   * We are trusting the following:
   * 1. `useMe()` is being rendered by a component under the (secure) route group.
   * 2. The (secure) route group layout is hydrating `me` into the react-query cache.
   */
  if (!me) {
    throw new Error('useMe: no user found.')
  }

  return { me, refetchMe }
}
