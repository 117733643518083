import { type GetAvatarUrl, userToPerson } from '@mntn-dev/app-common'
import { ActivityDomainQueryModelSchema } from '@mntn-dev/domain-types'
import type {
  ActivityDomainSelectModel,
  ActivityType,
} from '@mntn-dev/domain-types'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const ActivityViewModelSchema = (getAvatarUrl: GetAvatarUrl) =>
  ActivityDomainQueryModelSchema.required({
    actor: true,
  }).transform(({ actor, ...activity }) => ({
    ...activity,
    actor: userToPerson(actor, getAvatarUrl),
  }))

export type ActivityViewModel<Type extends ActivityType = ActivityType> =
  ActivityDomainSelectModel<Type> &
    ZodSimplify<ReturnType<typeof ActivityViewModelSchema>>

export const ActivityViewModelsSchema = (getAvatarUrl: GetAvatarUrl) =>
  z.array(ActivityViewModelSchema(getAvatarUrl))
